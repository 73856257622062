<template>
  <div class="global-header">
    <b-navbar toggleable="lg" is-nav>
      <b-container id="gynav" role="navigation">
        <b-navbar-brand class="gohome" href="/"><img class="logo" src="../assets/images/shrinklink-logo.png" alt="ShrinkLink logo"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav main">
            <li class="nav-item"><a class="nav-link" href="/">Create URL</a></li>
            <li class="nav-item"><a class="nav-link" href="/update">Update URL</a></li>
          </ul>

          <ul class="navbar-nav ml-auto right" v-if="loggedIn">
            <li class="nav-item"><a class="nav-link" href="/logout">
              <b-icon icon="lock-fill" class="locked" ></b-icon>
              Log Out</a>
            </li>
          </ul>
        </b-collapse>

      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      windowWidth: 0,
      loggedIn: true
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
      this.checkPage()
    })
  },
  methods: {
    getWindowWidth (event) {
      this.windowWidth = document.documentElement.clientWidth

      // small screen
      if (this.windowWidth <= 992) {
        document.getElementById('gynav').classList.add('container-fluid')
        document.getElementById('gynav').classList.remove('container')
      } else {
        document.getElementById('gynav').classList.add('container')
        document.getElementById('gynav').classList.remove('container-fluid')
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    },
    checkPage () {
      let currentPage = this.$route.path;
      if (currentPage.toLowerCase() === '/logout' || currentPage.toLowerCase() === '/login' || currentPage.toLowerCase() === '/404-error') {
        this.loggedIn = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  background-color: #fff;
  color: #252628;
  padding: .2rem .2rem .2rem 1rem;
}
.global-header :focus {
  outline: none;
}
.navbar-brand {
  font-family: "Barlow", serif;
  font-weight: 600;
}
.navbar .navbar-nav .nav-item, .navbar-nav .nav-item .nav-link {
  color: #252628;
  font-size: 18px;
}
.logo {
  height: 70px;
  width: auto;
  padding-left: 2%;
}
.navbar-nav .nav-item .nav-link:focus {
  color: #274ba7;
  border: none;
}
.navbar .navbar-toggler {
  background-color: rgba(255, 159, 53, 0.95);
  border-radius: 0px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  height: 1em;
  width: 1em;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;
}
ul {
  list-style: none;
  padding-inline-start: 0px;
}
.locked {
  color: #ff9f35;
}

@media (max-width: 767px) {
  .logo {
    padding: 5px 2px 5px 2%;
    height: 45px;
  }
  .navbar-toggler-icon {
    height: 1em;
    width: 1em;
  }
}
@media (max-width: 991px) {
  .navbar-nav .nav-item {
    border-bottom: 1px solid rgba(156,206,243,.9);
  }
}
@media (min-width: 992px) {
  .navbar {
    padding: 0rem 1rem;
  }
  .navbar-nav {
    margin-left: 15%;
  }
  .navbar-nav .nav-item {
    padding: 0px 22px;
  }
  .navbar-nav .nav-item .nav-link {
    margin: 12px 0;
    padding: 3px 2px;
  }
  .navbar-nav .nav-item .nav-link {
    border-bottom: 3px solid transparent;
  }
  .navbar-nav .nav-item a {
    -webkit-transition : border .5s linear;
    -moz-transition : border .5s linear;
    -o-transition : border .5s linear;
    transition : border .5s linear;
  }
  .navbar-nav .nav-item:hover a {
    border-color:#ff9f35;
    cursor: pointer;
  }
}
</style>

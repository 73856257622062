import Vue from 'vue'
import Router from 'vue-router'

import Create from '@/components/Create'
import Update from '@/components/Update'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import error404 from '@/components/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Create',
      component: Create
    },
    {
      path: '/update',
      name: 'Update',
      component: Update
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Log Out',
      component: Logout
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    }
  ]
})
